<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'" class="d-flex align-center">
                                <div style="word-break: keep-all;">
                                    <h5>{{ overallTeamSelection ? overallTeamSelection.name : 'Team' }} tournament wins
                                    </h5>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getWinningTeamsHurling" v-model="overallTeamSelection"
                                        @change="overallTeamSelectionChanged" placeholder="Select" label="Team"
                                        item-text="name" return-object outlined clearable hide-details hide-no-data>
                                        <template v-slot:item="{ item, on, attrs }">
                                            <v-list-item v-bind="attrs" v-on="on">
                                                <v-list-item-avatar>
                                                    <v-img :src="`/assets/gaa/teams/icons/${item.code}.WEBP`" contain
                                                        max-width="30" class="img_border"></v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <div style="display: flex; align-items: center;">
                                                <v-img :src="`/assets/gaa/teams/icons/${item.code}.WEBP`" contain
                                                    max-width="20" max-height="20" class="img_border"
                                                    style="margin-right: 5px;"></v-img>
                                                <span>{{ item.name }}</span>
                                            </div>
                                        </template>
                                    </v-select>
                                    <v-combobox v-else :items="getWinningTeamsHurling" v-model="overallTeamSelection"
                                        @change="overallTeamSelectionChanged" placeholder="Select" label="Team"
                                        item-text="name" return-object outlined clearable hide-details hide-no-data>
                                        <template v-slot:item="{ item, on, attrs }">
                                            <v-list-item v-bind="attrs" v-on="on">
                                                <v-list-item-avatar>
                                                    <v-img :src="`/assets/gaa/teams/icons/${item.code}.WEBP`" contain
                                                        max-width="30" class="img_border"></v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <div style="display: flex; align-items: center;">
                                                <v-img :src="`/assets/gaa/teams/icons/${item.code}.WEBP`" contain
                                                    max-width="20" max-height="20" class="img_border"
                                                    style="margin-right: 5px;"></v-img>
                                                <span>{{ item.name }}</span>
                                            </div>
                                        </template>
                                    </v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallCompetitionOptions" v-model="competition"
                                        placeholder="Select" label="Tournament" item-text="name" return-object outlined
                                        hide-details hide-no-data>
                                    </v-select>
                                    <v-combobox v-else :items="overallCompetitionOptions" v-model="competition"
                                        placeholder="Select" label="Tournament" item-text="name" return-object outlined
                                        hide-details hide-no-data>
                                    </v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="placementOption" v-model="placement" placeholder="Select"
                                        label="Result" item-text="name" outlined hide-details hide-no-data>
                                    </v-select>
                                    <v-combobox v-else :items="placementOption" v-model="placement" placeholder="Select"
                                        label="Result" item-text="name" outlined hide-details hide-no-data>
                                    </v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'px-1' : ''">
                        <div id="overall-table">
                            <v-data-table :headers="activeHeaders" :items="filteredFinals" sort-by="AllIrelandWins"
                                sort-desc class="elevation-1" mobile-breakpoint="0">

                                <template v-slot:item.runnerUp="{ item }">
                                    {{ calculateResultText(item) }}
                                </template>

                                <template v-slot:item.date="{ item }">
                                    {{ new Date(item.date).toLocaleDateString() }}
                                </template>

                                <template v-slot:item.score="{ item }">
                                    <span :style="calculateResultColour(item)">{{ calculateScoreText(item) }}</span>
                                </template>

                                <template v-slot:item.team="{ item }">
                                    <span style="display: flex; cursor: pointer;" v-if="overallTeamSelection" @click="goToProfile(item.homeTeam.id == overallTeamSelection.id ? item.awayTeam.id :
                                        item.homeTeam.id)">
                                        <v-img
                                            :src="`/assets/gaa/teams/icons/${item.homeTeam.id == overallTeamSelection.id ? item.awayTeam.code : item.homeTeam.code}.WEBP`"
                                            contain max-width="30" class="mr-2 img_border"></v-img>
                                        {{ item.homeTeam.id == overallTeamSelection.id ? item.awayTeam.name :
                                            item.homeTeam.name }}
                                    </span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import StatsCard from '@/components/card/statsCard/StatsCard'

export default {
    props: ['teamId'],
    name: 'TeamHonours',
    components: {
        StatsCard
    },
    async mounted() {
        await this.fetchWinners(this.$router.currentRoute.meta.sport.toUpperCase());
        this.loading = false;
        if (this.teamId) {
            this.overallTeamSelection = this.getWinningTeamsHurling.find(it => it && it.id == this.teamId);
            await this.fetchFinalsForTeam(this.teamId);
        }
    },
    data() {
        return {
            loading: true,
            competition: 'All',
            placement: 'All',
            overallTeamSelection: null,
            overallWinnerYearToFilter: 'All',
            overallTotalsYearFromFilter: 'All',
            overallTotalsYearToFilter: 'All',
            overallTableHeaders: [
                {
                    text: 'Year',
                    sortable: false,
                    value: 'year'
                },
                {
                    text: 'Opponent',
                    sortable: false,
                    value: 'team'
                },
                { text: 'Score', value: 'score', sortable: false },
                { text: 'Venue', value: 'venue', sortable: false },
                { text: 'Date', value: 'date', sortable: false },
                { text: 'Tournament', value: 'stage', sortable: false },
                { text: 'Result', value: 'runnerUp', sortable: false },
            ],
        }
    },
    computed: {
        ...mapGetters(['getWinningResults', 'getWinningTeamsHurling', 'getWinners', 'getFinals']),
        activeHeaders() {
            if (this.$vuetify.breakpoint.smAndUp) {
                return this.overallTableHeaders
            } else {
                let filterHeaders = this.overallTableHeaders.filter(it => it.value != 'index' && it.value != 'provincialRunnerUp');
                const index = filterHeaders.findIndex(item => item.value === "stage");

                if (index !== -1) {
                    const [item] = filterHeaders.splice(index, 1);
                    filterHeaders.splice(2, 0, item);
                }
                return filterHeaders;
            }
        },
        filteredFinals() {
            if (!this.overallTeamSelection) return [];
            let finals = this.getFinals;
            if (this.competition != 'All') {
                finals = finals.filter(it => it.stage == this.competition)
            }
            if (this.placement != 'All') {
                if (this.placement == 'Draw') {
                    finals = finals.filter((it) => { return it.result == 'DRAW' })
                } else if (this.placement == 'Champion') {
                    finals = finals.filter((it) => {
                        return (it.homeTeam.id == this.overallTeamSelection.id && it.result == 'HOME_WIN') ||
                            (it.awayTeam.id == this.overallTeamSelection.id && it.result == 'AWAY_WIN');
                    })
                } else {
                    finals = finals.filter((it) => {
                        return (it.homeTeam.id == this.overallTeamSelection.id && it.result == 'AWAY_WIN') ||
                            (it.awayTeam.id == this.overallTeamSelection.id && it.result == 'HOME_WIN');
                    });
                }
            }
            return finals;
        },
        overallWinnerYearToFilters() {
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => b - a));
            return years;
        },
        overallWinnerYearFromFilters() {
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => a - b));
            return years;
        },
        overallCompetitionOptions() {
            let stages = ['All'];
            stages.push(...this.$store.getters['getFinals'].map(it => it.stage));
            return stages;
        },
        placementOption() {
            return ['All', 'Champion', 'Runner Up', 'Draw']
        }
    },
    methods: {
        ...mapActions(['fetchWinners', 'updateOverallTeamSelections', 'updateOverallTotalsYearFromFilter', 'updateOverallTotalsYearToFilter', 'fetchFinalsForTeam']),
        goToProfile(id) {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/profile/${id}`)
        },
        overallTeamSelectionChanged() {
            if (this.overallTeamSelection) {
                this.fetchFinalsForTeam(this.overallTeamSelection.id);
            } else {
                this.fetchFinalsForTeam(null);
            }
            if (this.overallTeamSelection) {
                this.$router.push({
                    name: `Honours_${this.$router.currentRoute.meta.sport}`,
                    params: { mode: 5, teamId: this.overallTeamSelection.id }
                });
            }
        },
        overallTotalsYearFromFilterChanged() {
            this.updateOverallTotalsYearFromFilter(this.overallTotalsYearFromFilter);
        },
        overallTotalsYearToFilterChanged() {
            this.updateOverallTotalsYearToFilter(this.overallTotalsYearToFilter);
        },
        calculateScoreText(item) {
            if (item.homeTeam && this.overallTeamSelection && item.homeTeam.id == this.overallTeamSelection.id) {
                return `${item.homeGoals}-${item.homePoints} ${item.awayGoals}-${item.awayPoints}`
            } else {
                return `${item.awayGoals}-${item.awayPoints} ${item.homeGoals}-${item.homePoints}`
            }
        },
        calculateResultColour(item) {
            if (!this.overallTeamSelection) return '';
            if (item.homeTeam && item.homeTeam.id == this.overallTeamSelection.id) {
                if (item.result == 'HOME_WIN') {
                    return 'color: green';
                } else if (item.result == 'AWAY_WIN') {
                    return 'color: red';
                } else {
                    return '';
                }
            } else {
                if (item.result == 'HOME_WIN') {
                    return 'color: red';
                } else if (item.result == 'AWAY_WIN') {
                    return 'color: green';
                } else {
                    return '';
                }
            }
        },
        calculateResultText(item) {
            if (!this.overallTeamSelection) return '';
            if (item.result == 'DRAW') {
                return 'Draw';
            } else if (item.result == 'HOME_WIN') {
                if (item.homeTeam.id == this.overallTeamSelection.id) {
                    return 'Champion';
                } else {
                    return 'Runner Up';
                }
            } else {
                if (item.awayTeam.id == this.overallTeamSelection.id) {
                    return 'Champion';
                } else {
                    return 'Runner Up';
                }
            }
        }
    }
}
</script>

<style></style>